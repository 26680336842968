.individual {
  background-color: #dddddd !important;
  margin: 0px !important;
}

.navigationButton {
  background-color: #ce0e2d !important;
  border: none !important;
  font-weight: bold !important;
  color: white !important;
  font-size: 16px !important;
  margin-right: 10;
}
.logo {
  margin-right: 20px;
  margin-left: 20px;
  width: 35px;
  height: 35px;
}
