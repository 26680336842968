.disabledRow {
  pointer-events: none !important;
  color: #b8b8b8;
}

.viewProjectScreenLabel {
  font-weight: bold;
}
.highlightedInstruction {
  font-style: italic;
  margin-top: 15px;
  font-size: 16px;
  color: #ce0e2d;
}
.mdsaDashboardTextAreaInput {
  width: 500px !important;
}
.input {
  margin-top: 4px !important;
}
