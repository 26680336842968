html {
  --antd-wave-shadow-color: none !important;
}

.ant-btn-primary {
  border: none !important;
  background-color: #ce0e2d !important;
}

.ant-descriptions-item-label {
  font-weight: bold !important;
}

.ant-spin-dot-item {
  background-color: #ce0e2d !important;
}

.ant-menu-item-selected a {
  color: #ce0e2d !important;
}

.ant-menu-submenu-selected {
  color: #ce0e2d !important;
}

.ant-menu-item-active a {
  color: #ce0e2d !important;
}

.ant-menu-submenu-active div {
  color: #ce0e2d !important;
}

.ant-menu-item::after {
  border-right: 3px solid #ce0e2d !important;
}

.ant-checkbox-group-item {
  display: block !important;
  margin-top: 5px !important;
}

.ant-table-filter-trigger.active {
  color: #ce0e2d !important;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #ce0e2d !important;
}

/* Ongoing MDSA table styles */

.ongoingMdsaTableStyle .ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset !important;
}
.ongoingMdsaTableStyle .ant-table-thead .ant-table-cell {
  background-color: #fef1f3 !important;
}

.rootRowStyle {
  background-color: #f9f9f9;
}

/* Reduce the row height for the specific table class */
.qiimeMetadataTable .ant-table-tbody > tr > td,
.qiimeMetadataTable .ant-table-thead > tr > th {
  line-height: 1;
  font-size: 12px;
}

.qiimeMetadataTable {
  width: 100%;
}
